<template>
  <Layout class="main-container">
    <Row align="top">
      <Col span="24">
        <Button
          type="success"
          @click="handleAdd"
          v-if="$canAction(['ProductClass_Edit'])"
          icon="md-add-circle"
          >新增</Button
        >
        <Button
          type="warning"
          class="ml2"
          v-if="$canAction(['Sys_Cache_Edit'])"
          @click="$importCache('AllProductClass', $data)"
          >更新缓存<i>{{ cacheUpdatedTime }}</i></Button
        >
      </Col>
    </Row>
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj"
      highlight-row
      size="small"
      stripe
    >
      <template slot="Logo" slot-scope="{ row }">
        <Avatar style="background: #ccc" :src="$setImagePrefix(row.Logo)" />
      </template>
      <template slot="IsShow" slot-scope="{ row }">
        <Tag type="border" :color="row.IsShow ? 'green' : 'red'">{{
          row.IsShow ? "展示" : "不展示"
        }}</Tag>
      </template>
      <template slot="BrandID" slot-scope="{ row }">
        <Tag
          color="blue"
          v-for="(item, index) in getBrandName(row.BrandID)"
          :key="index"
          >{{ item }}</Tag
        >
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="success"
          v-if="$canAction(['ProductClass_Edit'])"
          @click="handleEdit(row)"
          >编辑</Button
        >
        <Button
          size="small"
          type="error"
          class="ml2"
          v-if="$canAction(['ProductClass_Delete'])"
          @click="handleDelete(row)"
          >删除</Button
        >
      </template>
    </Table>
    <Modal
      v-model="isShow"
      :title="classForm.ID ? '编辑分类' : '新增分类'"
      ok-text="保存"
      loading
      @on-ok="handleSave"
    >
      <Form
        :model="classForm"
        label-position="right"
        ref="form"
        :rules="validateRules"
        :label-width="90"
      >
        <FormItem label="分类LOGO:">
          <i-upload
            :fileKey="uploadType.ProductClass"
            @onChange="onChange"
            ref="UploadLogo"
            :max="1"
          ></i-upload>
        </FormItem>
        <!-- <FormItem label="分类编号:"
                  prop="Code">
          <Input v-model="classForm.Code"
                 placeholder="请输入分类编号" />
        </FormItem> -->
        <FormItem label="分类名称:" prop="Name">
          <Input v-model="classForm.Name" placeholder="请输入分类名称" />
        </FormItem>
        <FormItem label="排序:" prop="Index">
          <Input
            type="number"
            v-model="classForm.Index"
            placeholder="请输入排序"
          />
        </FormItem>
        <FormItem label="品牌:" prop="BrandID">
          <Select multiple filterable v-model="classForm.BrandID">
            <Option
              v-for="item in brands"
              :label="item.Name"
              :key="item.ID"
              :value="item.ID"
              ><span
                :style="{
                  marginLeft: item.Layer * 10 + 'px',
                  fontWeight: !item.Layer ? 'bold' : 'normal',
                }"
                >{{ item.Name }}</span
              ></Option
            >
          </Select>
        </FormItem>
        <FormItem label="是否显示:" prop="IsShow">
          <Checkbox v-model="classForm.IsShow">是否显示</Checkbox>
        </FormItem>
        <!-- <FormItem label="是否展示:"
                  prop="IsShowStr">
          <Select v-model="classForm.IsShowStr">
            <Option :value="1">展示</Option>
            <Option :value="0">不展示</Option>
          </Select>
        </FormItem> -->
      </Form>
      <div slot="footer">
        <Button @click="isShow = false">取消</Button>
        <Button type="primary" @click="handleSave" :loading="SaveLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
  </Layout>
</template>

<script>
import layout from "@/components/layout/index.vue";
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import { mapActions } from "vuex";
import iUpload from "@/components/upload/index.vue";
export default {
  name: "ProductClass",
  components: { iUpload, layout },
  data() {
    return {
      resObj: [],
      isShow: false,
      cacheUpdatedTime: "",
      tableHeight: 0,
      tableLoading: false,
      SaveLoading: false,
      columns: [
        { title: "Logo", key: "Logo", slot: "Logo", minWidth: 80 },
        { title: "ID", key: "ID", minWidth: 80, sortable: true },
        { title: "分类名称", key: "Name", minWidth: 90 },
        { title: "品牌", key: "BrandID", slot: "BrandID", minWidth: 200 },
        { title: "排序", key: "Index", minWidth: 80, sortable: true },
        { title: "是否展示", key: "IsShow", slot: "IsShow", minWidth: 80 },
      ],
      classForm: {
        Code: "",
        Index: "",
        Name: "",
        PID: 1,
        BrandID: "",
        Type: 10,
        IsShowStr: 1,
        Logo: "",
      },
      logoFile: [],
      validateRules: {
        Name: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
        BrandID: [
          { required: true, type: "array", message: "分类品牌不能为空" },
        ],
      },
    };
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getProductClassList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.getProductClassList();
        this.getProductBrandInfo();
        this.$getCacheUpdatedTime("AllProductClass", this);
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getProductClassList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.getProductClassList();
    this.getProductBrandInfo();
    this.$getCacheUpdatedTime("AllProductClass", this);
  },
  methods: {
    ...mapActions(["getProductBrandInfo"]),
    async getProductClassList() {
      try {
        this.tableLoading = true;
        const res = await api.GetProductClassList();
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
          this.tableLoading = false;
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
      }
    },
    handleEdit(row) {
      this.classForm = JSON.parse(JSON.stringify(row));
      this.classForm.BrandID = this.classForm.BrandID.split(",").map((item) =>
        Number(item)
      );
      this.classForm.IsShowStr = this.classForm.IsShow ? 1 : 0;
      this.logoFile = this.$refs.UploadLogo.uploadFiles = [
        {
          _key: row.Logo,
          _file: this.$setImagePrefix(row.Logo),
          success: true,
        },
      ];
      this.isShow = true;
    },
    handleDelete(row) {
      this.$Modal.confirm({
        title: "删除确定",
        content: `确定要删除【${row.Name}】分类吗？`,
        onOk: async () => {
          try {
            await api.DeleteProductClass({ id: row.ID });
            const index = this.resObj.findIndex((item) => item.ID === row.ID);
            if (index !== -1) this.resObj.splice(index, 1);
            this.$Message.success("删除成功");
            this.$Modal.remove();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
    async handleSave() {
      const _this = this;
      _this.SaveLoading = true;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (
            _this.$refs.UploadLogo.uploadFiles.length &&
            _this.logoFile.filter((f) => !f.success).length
          ) {
            const res = await _this.$refs.UploadLogo.Upload();
            if (res.success.length === 0) {
              _this.SaveLoading = false;
              return _this.$Message.error("分类LOGO上传失败");
            } else {
              _this.classForm.Logo = res.success[0];
            }
          }
          if (_this.$refs.UploadLogo.uploadFiles.length === 0) {
            _this.classForm.Logo = null;
          }
          let tempData = JSON.parse(JSON.stringify(_this.classForm));
          // if (_this.classForm.IsShowStr === 1) {
          //   tempData.IsShow = true;
          // } else {
          //   tempData.IsShow = false;
          // }
          if (!_this.classForm.BrandID) {
            return _this.$Message.error("请选择品牌");
          }
          _this.SaveLoading = true;
          tempData.BrandID = _this.classForm.BrandID.join(",");
          try {
            const response = await api.SaveProductClass({
              entity: tempData,
            });
            if (_this.classForm.ID) {
              const i = _this.resObj.findIndex(
                (item) => item.ID === this.classForm.ID
              );
              _this.resObj.splice(i, 1, tempData);
            } else {
              _this.resObj.push(response.Data);
            }
            _this.$Message.success("保存成功");
            _this.SaveLoading = false;
            _this.isShow = false;
          } catch (error) {
            _this.SaveLoading = false;
            _this.$Notice.error({ title: "错误", desc: error });
          }
        } else {
          this.$Message.error("填写信息有误！");
          this.SaveLoading = false;
        }
      });
    },
    handleAdd() {
      this.classForm = {
        Code: "",
        Index: "",
        Name: "",
        PID: 1,
        BrandID: "",
        Type: 10,
        IsShowStr: 1,
        Logo: "",
      };
      this.logoFile = this.$refs.UploadLogo.uploadFiles = [];
      this.$refs.form.resetFields();
      this.isShow = true;
    },
    onChange(opt) {
      this.logoFile = opt.files;
    },
    getBrandName(ids) {
      const _this = this;
      let nameArr = [];
      ids &&
        ids.split(",").forEach((id) => {
          const res = _this.brands.find((b) => b.ID === Number(id));
          if (res) nameArr.push(res.Name);
        });
      return nameArr;
    },
  },
  computed: {
    uploadType() {
      return bizEnum.UploadType;
    },
    brands() {
      return this.$store.state.product.brandList || [];
    },
  },
};
</script>

<style lang="less"></style>
