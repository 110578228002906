var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"main-container"},[_c('Row',{attrs:{"align":"top"}},[_c('Col',{attrs:{"span":"24"}},[(_vm.$canAction(['ProductClass_Edit']))?_c('Button',{attrs:{"type":"success","icon":"md-add-circle"},on:{"click":_vm.handleAdd}},[_vm._v("新增")]):_vm._e(),(_vm.$canAction(['Sys_Cache_Edit']))?_c('Button',{staticClass:"ml2",attrs:{"type":"warning"},on:{"click":function($event){return _vm.$importCache('AllProductClass', _vm.$data)}}},[_vm._v("更新缓存"),_c('i',[_vm._v(_vm._s(_vm.cacheUpdatedTime))])]):_vm._e()],1)],1),_c('Table',{ref:"dataTable",staticClass:"mt10 main-table",attrs:{"loading":_vm.tableLoading,"height":_vm.tableHeight,"columns":_vm.columns,"data":_vm.resObj,"highlight-row":"","size":"small","stripe":""},scopedSlots:_vm._u([{key:"Logo",fn:function(ref){
var row = ref.row;
return [_c('Avatar',{staticStyle:{"background":"#ccc"},attrs:{"src":_vm.$setImagePrefix(row.Logo)}})]}},{key:"IsShow",fn:function(ref){
var row = ref.row;
return [_c('Tag',{attrs:{"type":"border","color":row.IsShow ? 'green' : 'red'}},[_vm._v(_vm._s(row.IsShow ? "展示" : "不展示"))])]}},{key:"BrandID",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.getBrandName(row.BrandID)),function(item,index){return _c('Tag',{key:index,attrs:{"color":"blue"}},[_vm._v(_vm._s(item))])})}},{key:"Action",fn:function(ref){
var row = ref.row;
return [(_vm.$canAction(['ProductClass_Edit']))?_c('Button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]):_vm._e(),(_vm.$canAction(['ProductClass_Delete']))?_c('Button',{staticClass:"ml2",attrs:{"size":"small","type":"error"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")]):_vm._e()]}}])}),_c('Modal',{attrs:{"title":_vm.classForm.ID ? '编辑分类' : '新增分类',"ok-text":"保存","loading":""},on:{"on-ok":_vm.handleSave},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('Form',{ref:"form",attrs:{"model":_vm.classForm,"label-position":"right","rules":_vm.validateRules,"label-width":90}},[_c('FormItem',{attrs:{"label":"分类LOGO:"}},[_c('i-upload',{ref:"UploadLogo",attrs:{"fileKey":_vm.uploadType.ProductClass,"max":1},on:{"onChange":_vm.onChange}})],1),_c('FormItem',{attrs:{"label":"分类名称:","prop":"Name"}},[_c('Input',{attrs:{"placeholder":"请输入分类名称"},model:{value:(_vm.classForm.Name),callback:function ($$v) {_vm.$set(_vm.classForm, "Name", $$v)},expression:"classForm.Name"}})],1),_c('FormItem',{attrs:{"label":"排序:","prop":"Index"}},[_c('Input',{attrs:{"type":"number","placeholder":"请输入排序"},model:{value:(_vm.classForm.Index),callback:function ($$v) {_vm.$set(_vm.classForm, "Index", $$v)},expression:"classForm.Index"}})],1),_c('FormItem',{attrs:{"label":"品牌:","prop":"BrandID"}},[_c('Select',{attrs:{"multiple":"","filterable":""},model:{value:(_vm.classForm.BrandID),callback:function ($$v) {_vm.$set(_vm.classForm, "BrandID", $$v)},expression:"classForm.BrandID"}},_vm._l((_vm.brands),function(item){return _c('Option',{key:item.ID,attrs:{"label":item.Name,"value":item.ID}},[_c('span',{style:({
                marginLeft: item.Layer * 10 + 'px',
                fontWeight: !item.Layer ? 'bold' : 'normal',
              })},[_vm._v(_vm._s(item.Name))])])}),1)],1),_c('FormItem',{attrs:{"label":"是否显示:","prop":"IsShow"}},[_c('Checkbox',{model:{value:(_vm.classForm.IsShow),callback:function ($$v) {_vm.$set(_vm.classForm, "IsShow", $$v)},expression:"classForm.IsShow"}},[_vm._v("是否显示")])],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{on:{"click":function($event){_vm.isShow = false}}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary","loading":_vm.SaveLoading},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(!_vm.SaveLoading ? "保存" : "保存中...")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }